import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, LinkBox, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Mobivgo
			</title>
			<meta name={"description"} content={"Розкрийте свій потенціал - випробуйте повний спектр тенісної майстерності\n"} />
			<meta property={"og:title"} content={"Mobivgo"} />
			<meta property={"og:description"} content={"Розкрийте свій потенціал - випробуйте повний спектр тенісної майстерності"} />
			<meta property={"og:image"} content={"https://mobivgo.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://mobivgo.com/img/43623623.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://mobivgo.com/img/43623623.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://mobivgo.com/img/43623623.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://mobivgo.com/img/43623623.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://mobivgo.com/img/43623623.png"} />
			<meta name={"msapplication-TileImage"} content={"https://mobivgo.com/img/43623623.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" lg-padding="56px 0 56px 0" sm-padding="32px 0 32px 0" background="--color-light">
			<Box
				margin="0px 0px 32px 0px"
				md-margin="0px 0px 40px 0px"
				lg-margin="0px 0px 33px 0px"
				display="flex"
				flex-direction="column"
				align-items="center"
				lg-padding="15px 15px 15px 15px"
				sm-margin="0px 0px 10px 0px"
				justify-content="center"
			>
				<Text
					font="--headline2"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Наші послуги
				</Text>
				<Text font="--lead" color="--darkL2" text-align="center" md-width="100%">
				Тенісний хаб Mobivgo надає широкий спектр послуг, покликаних покращити вашу гру в теніс та збагатити ваш загальний досвід. Від комплексних тренувальних програм до кортів преміум-класу - ми є вашим універсальним місцем для досягнення тенісної досконалості.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-wrap="wrap"
				width="100%"
				margin="0px 0px 16px 0px"
				justify-content="flex-start"
				md-margin="0px 0px 16px 0px"
			>
				<Box
					width="100%"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					md-width="100%"
					lg-width="100%"
				>
					<Image
						src="https://mobivgo.com/img/4.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="522px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						object-position="0% 30%"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
					Тренувальні програми
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
					- Юніорський розвиток: Призначені для юних ентузіастів, зосереджені на основах, техніці та радості від гри.
						<br />
						<br />
						- Клініки для дорослих: Незалежно від того, чи ви новачок, чи прагнете вдосконалити свої навички, наші програми для дорослих задовольняють всі рівні, поєднуючи технічні, тактичні та фітнес-тренування.
						<br />
						<br />
						- Індивідуальні тренування: індивідуальні заняття з нашими тренерами-експертами, адаптовані до ваших особистих цілей та графіку для прискореного вдосконалення.
					</Text>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
						Корти
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
						- Криті корти: Насолоджуйтесь грою на наших високоякісних критих кортах цілий рік, гарантуючи, що вашій грі ніколи не доведеться зупинятися через погоду.
						<br />
						<br />
						- Відкриті корти: Відчуйте захоплення від гри під відкритим небом на наших доглянутих відкритих кортах, призначених як для змагальних матчів, так і для звичайної гри.
					</Text>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
						Турніри
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
						- Ліги та турніри: Беріть участь у дружніх змаганнях, перевіряйте свої навички та знайомтеся з іншими ентузіастами тенісу в наших організованих лігах та турнірах.
						<br />
						<br />
						- Матч-плей сесії: Регулярно заплановані ігрові сесії, які надають членам можливість попрактикуватися у змагальній грі в сприятливому середовищі.
					</Text>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
						Здоров'я та фітнес
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
						- Програми підготовки: Фітнес-сесії для тенісу, спрямовані на покращення сили, спритності та витривалості на корті.
						<br />
						<br />
						- Відновлення та оздоровлення: Доступ до інструментів відновлення та оздоровчих порад, які допоможуть вам залишатися на висоті, як фізично, так і психологічно.
					</Text>
				</Box>
			</LinkBox>
		</Section>
		<Section background="--color-primary" padding="60px 0" sm-padding="40px 0">
			<Box
				margin="-16px -16px -16px -16px"
				padding="0px 0px 0px 0px"
				display="flex"
				width=" "
				flex-wrap="wrap"
			>
				<Box padding="16px 16px 16px 16px" display="flex" width="50%" lg-width="100%">
					<Box
						padding="98px 64px 98px 64px"
						display="flex"
						flex-direction="column"
						background="--color-light"
						color="--dark"
					>
						<Text as="h2" margin="12px 0" font="--headline2">
						Спільнота та події
						</Text>
						<Text margin="12px 0" font="--lead" md-font="--headline3">
						- Соціальні мікстери: Регулярні заходи, які об'єднують нашу тенісну спільноту, ідеально підходять для того, щоб завести нових друзів і поспілкуватися з колегами-гравцями.
							<br />
							<br />
							- Спеціальні клініки та майстер-класи: Періодичні клініки та воркшопи із запрошеними тренерами, що пропонують унікальні ідеї та можливості для навчання.
						</Text>
					</Box>
				</Box>
				<Box display="flex" width="50%" flex-wrap="wrap" lg-width="100%">
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="100%"
						lg-width="33.3333%"
						md-width="100%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 624px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://mobivgo.com/img/5.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://mobivgo.com/img/6.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://mobivgo.com/img/7.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Звʼяжіться з нами
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Щоб отримати більш детальну інформацію або стати частиною нашої яскравої тенісної спільноти, ми запрошуємо вас зв'язатися з нами. Наша команда з радістю допоможе вам у створенні вашої тенісної подорожі в Mobivgo.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://mobivgo.com/img/8.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});